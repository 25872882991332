import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../api";
import * as FileSaver from "file-saver";

const Qrcode = () => {
  const location = useLocation();
  const { selectedCards, totalcash, snValue, device_info } = location.state || {
    selectedCards: [],
    totalcash: [],
    snValue: "",
    device_info: [],
  };
  const navigate = useNavigate();
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [browser, setBrowser] = useState(null);

  const [loading, setLoading] = useState(false);

  const handlealertClick = (data) => {
    navigate("/alert", { state: { selectedCards, snValue, device_info } });
  };

  const [timeLeft, setTimeLeft] = useState(600);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          navigate(-1);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const combinedValues = selectedCards
      .map((item) => String(item.device_number).padStart(2, "0"))
      .join("");

    const orderDescs = `${snValue}-${combinedValues}`;
    const fetchData = async () => {
      setLoading(true);
      try {
        const response1 = await api
          .post(
            `/OPS/ops_order/qr/CIMB`,
            {
              orderDesc: orderDescs,
              amount: totalcash,
              "product_id": selectedCards?.[0]?.product_id,
              "device_id": device_info?.data?.[0]?.id
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (ret) => {
            const { data } = ret;
            console.log(data);
            if (data?.data) {
              const response2 = await api.post(
                `/OPS/ops_order_response/${data?.data?.orderId}`,
                {}
              );
              const { data: data2 } = response2;
              const response3 = await api.get(
                `/OPS/ops_order/qr/orderid/${data2?.data?.order?.orderId}`
              );
              const { data: data3 } = response3;
              setQrCodeBase64(data3?.qrcode);
              setDataOrder(data3);
            } else {
              navigate("/alert");
            }
          })
          .catch(function (error) {
            navigate("/alert");
          });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const sevefile = async () => {
    // import { saveAs } from "file-saver";
    const b64 = "data:image/png;base64," + qrCodeBase64;
    // saveAs(b64, "image.png");
    FileSaver.saveAs(b64, "QR.png");
  };
  useEffect(() => {
    let interval;
    if (dataOrder) {
      interval = setInterval(async () => {
        const response = await getCallback(dataOrder);
        if (response?.data?.[0]?.callback_status == 0) {
          handlealertClick(dataOrder);
        }
      }, 5000);
    }
    return () => clearInterval(interval); // This is important
  }, [dataOrder]);

  const getCallback = async (data) => {
    const response = await api.get(
      `/OPS/ops_order?orderId=${data?.data?.orderId}`
    );
    return response.data;
  };
  const userAgent = window.navigator.userAgent;
  useEffect(() => {
    if (!browser) {
      if (userAgent.includes('Firefox')) {
        setBrowser('Firefox');
      } else if (userAgent.includes('Chrome')) {
        setBrowser('Chrome');
      } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        setBrowser('Safari');
      } else if (userAgent.includes('Edge')) {
        setBrowser('Edge');
      } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
        setBrowser('Internet Explorer');
      } else {
        setBrowser('Unknown');
      }
    }

  }, [userAgent]);



  return (
    <div>
      {loading ? (
        <Box
          style={{ height: "100dvh" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={80} />
          <Typography position="absolute">loading...</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            margin: 0,
            textAlign: "center",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 8,
              padding: 0,
              marginTop: 2,
            }}
          >
            <Card
              sx={{
                width: 366,
                height: 452,
                borderRadius: "8px",
                boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
                textAlign: "center",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  margin: "16px 32px",
                }}
              >
                <img
                  src="/logos/TopPrompay.png"
                  alt="TopCard"
                  className=" "
                  style={{
                    width: "100%",
                    height: "50px",
                  }}
                />
              </Box>

              <CardContent sx={{ padding: 0 }}>
                <Box
                  className="flex justify-content-center"
                  sx={{ marginTop: 0 }}
                >
                  <img
                    src="/logos/prompay.png"
                    alt="Banner"
                    className="border-prompayQr plr-4"
                    style={{
                      width: "96px",
                      height: "32px",
                    }}
                  />
                </Box>
                <Box
                  className="flex justify-content-center"
                  sx={{ marginTop: 0 }}
                >
                  <img
                    src={`data:image/png;base64,${qrCodeBase64}`}
                    alt="QR Code"
                    style={{
                      width: "240px",
                      height: "240px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 500,
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  {formatTime(timeLeft)}
                </Typography>
              </CardContent>

              <Box
                sx={{
                  backgroundColor: "#0099FF",
                  padding: "14px",
                  margin: "6px 8px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Kanit, sans-serif ",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#FFFFFF",
                  }}
                >
                  ยอดชำระ {totalcash} บาท
                </Typography>
              </Box>
            </Card>

            <Box sx={{ zIndex: 1 }}>
              {browser != "Chrome" ? <>
                <Typography sx={{
                  fontSize: "18px",
                  fontWeight: "400", fontFamily: "Kanit, sans-serif"
                }}>กรุณา Capture หน้าจอ </Typography>
                <Typography sx={{
                  fontSize: "18px",
                  fontWeight: "400", fontFamily: "Kanit, sans-serif"
                }}>หรือ</Typography>
                <Typography sx={{
                  fontSize: "18px",
                  fontWeight: "400", fontFamily: "Kanit, sans-serif"
                }}>กดค้างที่ QR code เพื่อดาวน์โหลด</Typography>
              </>
                :
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: "12px 24px",
                    background: "#006EF9",
                    borderRadius: "32px",
                    boxShadow: "0px 3px 4px 0px #00000040",
                    width: "366px",
                    height: "51px",
                    "&:hover": {
                      background: "#0099FF",
                    },
                    fontSize: "22px",
                    fontWeight: "500",
                    fontFamily: "Kanit, sans-serif",
                    color: "#FFFFFF",
                  }}
                  onClick={sevefile}
                >
                  ดาวน์โหลด QR Code
                </Button>}


            </Box>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              backgroundImage: "url('/logos/Wave2.png')",
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "328px",
              marginTop: "auto",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default Qrcode;
