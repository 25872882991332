import React, { useState, useEffect, useRef } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { api, base_url } from "../../api";

const ScrollableRow = styled(Box)({
  display: "flex",
  overflowX: "auto",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  padding: "6px",
});

const MainSelect = ({ device_info, snValue }) => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [totalcash, setTotalcash] = useState("");
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const rowRefs = useRef([]);

  console.log(device_info);

  var cardData = [
    {
      ID: 1,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 2,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 3,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 4,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 5,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 6,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 7,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 8,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 9,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 10,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 11,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 12,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 13,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 14,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 15,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 16,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 17,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 18,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 19,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 20,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 21,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 22,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
    {
      ID: 23,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 24,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 25,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 26,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 27,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 28,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 29,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 0,
      price: 120,
    },
    {
      ID: 30,
      name: "น้ำดื่มเนสท์เล่เพียวไลฟ์ 1500 มล. (แพ็ก 6 ขวด)",
      count: 2,
      price: 120,
    },
  ];

  const handleCardClick = (card) => {
    if (card.status === 0) return;

    setSelectedCards((prevSelectedCards) => {
      const isSelected = prevSelectedCards.some(
        (selectedCard) => selectedCard.id === card.id
      );
      const newSelectedCards = isSelected
        ? prevSelectedCards.filter(
            (selectedCard) => selectedCard.id !== card.id
          )
        : [...prevSelectedCards, card];

      console.log("Selected Cards:", newSelectedCards);
      const totalPrice = newSelectedCards.reduce(
        (total, card) => total + card.price,
        0
      );
      console.log("Total Price:", totalPrice);
      setTotalcash(totalPrice);

      return newSelectedCards;
    });
  };

  const handlePaymentClick = () => {
    navigate("/payment", {
      state: { selectedCards, totalcash, snValue, device_info },
    });
  };

  const isAnyCardSelected = selectedCards.length > 0;

  // var rows = []\
  useEffect(() => {
    // api
    //   .get(
    //     `/device`,
    //     {
    //       params:{
    //         sn:snValue
    //       }
    //     }
    //   )
    //   .then(async (ret) => {
    //     const { data } = ret;
    //     setDevice_info(data)
    if (device_info?.data?.length > 0) {
      api
        .get(`/device_number`, {
          params: {
            device_id: device_info?.data?.[0]?.id,
            sort: "asc",
            order_by: "device_number",
          },
        })
        .then(async (ret) => {
          const { data } = ret;
          for (const [i, v] of data?.data?.entries()) {
            // cardData[i].status = v.status
            cardData[i] = { ...cardData[i], ...v };
          }
          setRows(
            Array.from({ length: 5 }, (_, rowIndex) =>
              cardData.slice(rowIndex * 6, rowIndex * 6 + 6)
            )
          );
        })
        .catch(function (error) {});
    }
    // })
    // .catch(function (error) {});
  }, [device_info]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      rowRefs.current.forEach((row) => {
        if (row) {
          const rowElements = rows[row.id];
          const elementCount = rowElements.length;
          const elementWidth = row.scrollWidth / elementCount;
          let scrollToPosition = 0;

          rowElements.some((element, index) => {
            if (element.status > 0) {
              scrollToPosition = elementWidth * index - elementWidth * 0.25; // Add margin if necessary
              return true; // Exit loop once we find the first element with status > 0
            }
          });

          console.log(scrollToPosition);

          row.scrollTo({
            left: scrollToPosition, // Scroll left by the calculated position
            behavior: "smooth",
          });
        }
      });
    }, 400); // Delay the execution by 3 seconds

    return () => clearTimeout(timeoutId); // Cleanup the timeout if rows change or the component unmounts
  }, [rows]);

  return (
    <Box sx={{ padding: "0", paddingTop: "8px" }}>
      <div className="p-8 plr-16">
        <Typography align="left" className="font-list">
          รายการสินค้า
        </Typography>
      </div>
      {/* 
      {rows.map((row, rowIndex) => (
        <ScrollableRow
          key={rowIndex}
          id={rowIndex}
          ref={(el) => (rowRefs.current[rowIndex] = el)}
          sx={{ marginBottom: 2 }}
          className="select-center"
        >
          {row.map((card) => (
            <Card
              key={card?.id}
              onClick={() => handleCardClick(card)}
              sx={{
                position: "relative",
                width: "140px",
                height: "200px",
                marginRight: "8px",
                marginLeft: "8px",
                flexShrink: 0,
                border: selectedCards.some(
                  (selectedCard) => selectedCard.id === card.id
                )
                  ? "3px solid #006EF980"
                  : card.status === 0
                  ? "3px solid #D8D8D8E5"
                  : "3px solid #FFFFFF",
                boxShadow: selectedCards.some(
                  (selectedCard) => selectedCard.id === card.id
                )
                  ? "0px 4px 4px 0px #0074EC80"
                  : "0px 4px 4px 0px #00000040",
                cursor: "pointer",
                borderRadius: "15px",
                transition: "border 0.3s ease",
              }}
            >
              {card?.status === 0 ? (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#D8D8D8E5",
                    zIndex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography align="center" className="font-item-sold">
                    สินค้าหมด
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              <CardContent
                sx={{ padding: "0px !important", margin: "0px !important" }}
              >
                <div
                  className="text-align-center p-4 flex justify-content-center"
                  style={{ position: "relative" }}
                >
                  <img
                    // src="/logos/item1.png"
                    src={`${base_url + "/download/" + card?.product?.image}`}
                    alt="Banner"
                    style={{ height: 130 }}
                    // className="w-full h-auto"
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 8,
                      right: 8,
                      width: 40,
                      height: 40,
                      backgroundColor: "#0074EC",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kanit, sans-serif",
                        fontWeight: 800,
                        fontSize: "22px",
                        color: "#FFFFFF",
                      }}
                    >
                      X{card?.qty}
                    </Typography>
                  </Box>
                </div>

                <div className="">
                  <Typography align="left" className="font-item-title plr-4">
                    {card?.product?.name}
                  </Typography>
                </div>
                <div className="flex justify-content-center ">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "48px",
                      height: "22px",

                      backgroundColor: selectedCards.some(
                        (selectedCard) => selectedCard.id === card.id
                      )
                        ? "#0074EC"
                        : "#B2B2B2",
                      borderRadius: "3px",
                      margin: "auto",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "22px",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontFamily: "Kanit, sans-serif",
                      }}
                    >
                      {card?.device_number}
                    </Typography>
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))}
        </ScrollableRow>
      ))} */}
      <div style={{ width: "100%", padding: "0px 0px" }}>
        {rows.map((row, rowIndex) => (
          <div style={{ width: "100%" }}>
            <Box
              key={rowIndex}
              id={rowIndex}
              ref={(el) => (rowRefs.current[rowIndex] = el)}
              sx={{
                marginBottom: 2,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
              className="select-center flex"
            >
              {row.map((card) => (
                <Card
                  key={card?.id}
                  onClick={() => handleCardClick(card)}
                  sx={{
                    position: "relative",
                    flexBasis: "calc(16.66% - 10px)",
                    maxWidth: "140px",
                    height: "100%",
                    flexShrink: 0,
                    margin: "0px 5px",
                    outline: selectedCards.some(
                      (selectedCard) => selectedCard.id === card.id
                    )
                      ? "2px solid #006EF9"
                      : card.status === 0
                      ? "none"
                      : "none",
                    boxShadow: selectedCards.some(
                      (selectedCard) => selectedCard.id === card.id
                    )
                      ? "0px 4px 4px 0px #0074EC80"
                      : "0px 4px 4px 0px #00000040",
                    cursor: "pointer",
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "outline 0.1s ease",
                  }}
                >
                  {card?.status === 0 ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#D8D8D8E5",
                        zIndex: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography align="center" className="font-item-sold2">
                        สินค้าหมด
                      </Typography>
                    </Box>
                  ) : null}

                  <CardContent
                    sx={{ padding: "0px !important", margin: "0px !important" }}
                  >
                    <div
                      className="text-align-center flex justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={`${
                          base_url + "/download/" + card?.product?.image
                        }`}
                        alt="Banner"
                        style={{
                          height: "100%",
                          width: "100%",
                          padding: 6,
                          margin: "1px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor:
                            card?.status === 0 ? "#B2B2B2" : "#006EF9",
                          borderRadius: "4px 10px 4px 4px",
                          width: "29px",
                          height: "23px",
                          zIndex: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Dangrek, sans-serif",
                            fontWeight: 400,
                            fontSize: "23px",
                            color: "#FFFFFF",
                            lineHeight: 1,
                          }}
                        >
                          {String(card?.device_number).padStart(2, "0")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -1,
                          left: 0,
                          width: 0,
                          height: 0,
                          borderRight: "35px solid transparent",
                          borderBottom: "35px solid #006EF9",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 3,
                          left: 3,
                          zIndex: 4,
                          transform: "rotate(45deg)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Kanit, sans-serif",
                            fontWeight: 800,
                            fontSize: "12px",
                            color: "#FFFFFF",
                          }}
                        >
                          X{card?.qty}
                        </Typography>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </div>
        ))}
      </div>

      {isAnyCardSelected && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#FFFFFFCC",
            padding: "16px",
            boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              fontWeight: "bold",
              background: "#006EF9",
              borderRadius: "32px",
              width: "366px",
              height: "51px",
              fontFamily: "Kanit, sans-serif",
              fontWeight: 500,
              fontSize: "22px",
              color: "#FFFFFF",
              "&:hover": {
                background: " #0099FF",
              },
            }}
            onClick={handlePaymentClick}
          >
            ชำระเงิน ( {totalcash} บาท )
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MainSelect;
