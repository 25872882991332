import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Content from "./Components/Content";
import Payments from "./Components/Main/Payments/Payments";
import Qrcode from "./Components/Main/Payments/Qrcode";
import Wallet from "./Components/Main/Payments/Wallet";
import CreditCard from "./Components/Main/Payments/CreditCard";
import Alert from "./Components/Main/Completes/Alert";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/payment" element={<Payments />} />
        <Route path="/payment/qrcode" element={<Qrcode />} />
        <Route path="/payment/credit" element={<CreditCard />} />
        <Route path="/payment/wallet" element={<Wallet />} />
        <Route path="/alert" element={<Alert />} />
      </Routes>
    </Router>
  );
}

export default App;
