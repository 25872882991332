import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { base_url } from "../../api";

const testimages = [
  {
    original: "/logos/1.png",
    thumbnail: "/logos/1.png",
  },
  {
    original: "/logos/2.png",
    thumbnail: "/logos/2.png",
  },
  {
    original: "/logos/3.png",
    thumbnail: "/logos/3.png",
  },
];

const Header = ({ device_info, snValue }) => {
  const Headerimages =
    device_info?.data?.[0].device_images?.map((item) => ({
      original: `${base_url + "/download/" + item?.image}`,
      thumbnail: `${base_url + "/download/" + item?.image}`,
    })) || [];
  console.log(Headerimages);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        textAlign: "center",
        md: { textAlign: "left" },
      }}
    >
      <Box className="flex-1">
        <Typography
          className="font-header pl-128"
          sx={{
            textAlign: "left",
            marginTop: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          EasyDrink ยินดีให้บริการค่ะ
        </Typography>
        <Box
          className="flex pl-128"
          sx={{ paddingLeft: 3.5, paddingRight: 4, marginBottom: 2 }}
        >
          <LocationOnIcon sx={{ fontSize: 24 }} />
          <Typography
            className="font-subheader"
            sx={{
              textAlign: "left",
            }}
          >
            {device_info?.data?.[0]?.device_name}
          </Typography>
        </Box>
      </Box>
      <Box className="flex-1 custom-gallery">
        <ImageGallery
          items={
            device_info?.data?.[0].device_images?.length
              ? Headerimages
              : testimages
          }
          // items={Headerimages}
          showThumbnails={false}
          showNav={false}
          autoPlay={true}
          showPlayButton={false}
          showBullets={true}
          showFullscreenButton={false}
          // infinite={true}
          slideInterval={3000}
        />
      </Box>
    </Box>
  );
};

export default Header;
